import aesjs from 'aes-js'

const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
export default {
  methods: {
    encrypt (text) { //зашифровать
      const textBytes = aesjs.utils.utf8.toBytes(text)
      const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
      const encryptedBytes = aesCtr.encrypt(textBytes)
      return aesjs.utils.hex.fromBytes(encryptedBytes)
    },
    async decrypt (text) {//расшифровать
      const toBytes = aesjs.utils.hex.toBytes(text)
      const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
      const decryptedBytes = aesCtr.decrypt(toBytes)
      return aesjs.utils.utf8.fromBytes(decryptedBytes)
    }
  }
}